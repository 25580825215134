import React, { useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import { Widget } from "@typeform/embed-react";
import styled from "styled-components";
import logo_full from "./assets/img/group_pirate@1x.jpeg";
import { MontserratBoldBlack48px } from "./styledMixins";

function Home() {
  const [isFormAvailable, setIsFormAvailable] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [countdown, setCountdown] = useState('');

  // Definir horário de Brasília
  const openingTime = new Date('2025-01-23T21:00:00-03:00'); // Usar o horário de Brasília (GMT-3)

  useEffect(() => {
    const interval = setInterval(() => {
      // Atualizar o horário atual com fuso horário de Brasília
      const now = new Date();
      setCurrentTime(now);

      const timeDifference = openingTime - now;

      if (timeDifference <= 0) {
        setIsFormAvailable(true);
        clearInterval(interval); // Para a contagem assim que o formulário é liberado
      } else {
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        setCountdown(`${hours}h ${minutes}m`);
      }
    }, 1000);

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, []);

  const Group = styled.img`
    width: 430px;
    height: auto;
    padding: 7%;
    @media(max-width: 820px){
      width: 50%;
      padding: 0;
      margin-bottom: 30px;
    }
  `;


  if (isMobile) {
    return (
      <div>
        {!isFormAvailable ? (
          <div style={{ textAlign: 'center' }}>
          <p style={{ 
            marginTop: '50px', 
            fontSize: '48px', 
            fontWeight: 'bold', 
            fontFamily: '"Montserrat", Helvetica',
            fontWeight: '700',
            color: '#f44566',
            letterSpacing: '-0.13px'
          }}>CALA MARE</p>
          <Group src={logo_full} />
          <p style={{ 
            padding: '0px 40px',
            fontSize: '18px', 
            fontWeight: 'bold', 
            fontFamily: '"Montserrat", Helvetica',
            fontWeight: '700',
            color: '#1d1d1b',
            lineHeight: '1.3',
          }}>Inscrições para a Temporada 2025 do Azul Limão Alado a partir de 23/01/2025 às 21h.</p>
          <p style={{ 
            padding: '20px 40px',
            fontSize: '22px', 
            fontWeight: 'bold', 
            fontFamily: '"Montserrat", Helvetica',
            fontWeight: '700',
            color: '#1d1d1b',
            letterSpacing: '-0.13px',
            color: '#4ec8c1',
            width: '100%'
          }}>Faltam: {countdown}</p>
        </div>
      ) : (
          <div className="container-center-horizontal">
            <Widget id="us4uR3Fz" style={{ width: "100%", height: "100%", padding: "50px" }} className="my-form" />;
          </div>
          )}
      </div>
    );
  } else {
    return (
      <div>
        {!isFormAvailable ? (
          <div style={{ textAlign: 'center' }}>
            <p style={{ 
              marginTop: '50px', 
              fontSize: '48px', 
              fontWeight: 'bold', 
              fontFamily: '"Montserrat", Helvetica',
              fontWeight: '700',
              color: '#f44566',
              letterSpacing: '-0.13px'
            }}>CALA MARE</p>
            <Group src={logo_full} />
            <p style={{ 
              padding: '0px 40px',
              fontSize: '20px', 
              fontWeight: 'bold', 
              fontFamily: '"Montserrat", Helvetica',
              fontWeight: '700',
              color: '#1d1d1b',
              letterSpacing: '-0.13px'
            }}>Inscrições para a Temporada 2025 do Azul Limão Alado a partir de 23/01/2025 às 21h.</p>
            <p style={{ 
              padding: '20px 40px',
              fontSize: '30px', 
              fontWeight: 'bold', 
              fontFamily: '"Montserrat", Helvetica',
              fontWeight: '700',
              color: '#1d1d1b',
              letterSpacing: '-0.13px',
              color: '#4ec8c1',
              width: '100%'
            }}>Faltam: {countdown}</p>
          </div>
        ) : (
          <div className="container-center-horizontal">
            <Widget id="us4uR3Fz" style={{ width: "100%", height: "100%", padding: "100px" }} className="my-form" />;
          </div>
          )}
      </div>
    );
  }

}

export default Home;
